var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full px-4 xl:px-0 xl:w-8/12 2xl:max-w-4xl mx-auto"},[_c('v-tabs',{staticClass:"mb-8 shadow rounded-tr-lg rounded-tl-lg overflow-hidden",attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Account")]),_c('v-tab',[_vm._v("Notifications")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-4 pa-md-8 border-t"},[(!_vm.isUserLoading)?_c('FormWrapper',{attrs:{"item":_vm.user},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clone = ref.clone;
var valid = ref.valid;
var rules = ref.rules;
var save = ref.save;
return [_c('h5',[_vm._v("Identity")]),_c('v-divider',{staticClass:"mb-8"}),_c('div',{staticClass:"d-flex justify-content-between gap-8 mb-8"},[_c('v-text-field',{attrs:{"hide-details":"","label":"First Name","outlined":"","dense":"","rules":[rules.required]},model:{value:(clone.firstName),callback:function ($$v) {_vm.$set(clone, "firstName", $$v)},expression:"clone.firstName"}}),_c('v-text-field',{attrs:{"hide-details":"","label":"Last Name","outlined":"","dense":"","rules":[rules.required]},model:{value:(clone.lastName),callback:function ($$v) {_vm.$set(clone, "lastName", $$v)},expression:"clone.lastName"}})],1),_c('v-text-field',{staticClass:"mb-8",attrs:{"hide-details":"","label":"Email","outlined":"","dense":"","rules":[rules.required]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-divider'),_c('v-btn',{staticClass:"mt-8",attrs:{"depressed":"","block":"","disabled":!valid,"color":"primary"},on:{"click":function($event){return _vm.saveUser(save)}}},[_vm._v("Save")])]}}],null,false,1300037910)}):_vm._e()],1),_c('v-tab-item',{staticClass:"pa-4 pa-md-8 border-t"},[(!_vm.isUserLoading)?_c('FormWrapper',{attrs:{"item":_vm.userPreferences},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clone = ref.clone;
var valid = ref.valid;
var save = ref.save;
return [_c('div',{staticClass:"text-gray-600"},[_vm._v("Receive Emails")]),_c('div',{staticClass:"text-xs text-gray-400 mb-2"},[_vm._v("Opt in to receive emails when selected statuses are assigned.")]),_c('div',{staticClass:"d-flex mb-8",class:'flex-column align-start align-md-center flex-md-row mt-6 w-1/2'},[_c('v-select',{class:{ 'opacity-50': !clone.byEmail },attrs:{"hide-details":"","items":_vm.statuses,"dense":"","multiple":"","outlined":"","label":"Statuses"},model:{value:(clone.emailStatuses),callback:function ($$v) {_vm.$set(clone, "emailStatuses", $$v)},expression:"clone.emailStatuses"}})],1),_c('div',{staticClass:"text-gray-600"},[_vm._v("Recieve Notifications")]),_c('div',{staticClass:"text-xs text-gray-400 mb-2"},[_vm._v("Opt in to receive in-app notifications when selected statuses are assigned.")]),_c('div',{staticClass:"d-flex mb-8",class:'flex-column align-start align-md-center flex-md-row mt-6 w-1/2'},[_c('v-select',{class:{ 'opacity-50': !clone.byNotification },attrs:{"hide-details":"","items":_vm.statuses,"dense":"","multiple":"","outlined":"","label":"Statuses"},model:{value:(clone.notificationStatuses),callback:function ($$v) {_vm.$set(clone, "notificationStatuses", $$v)},expression:"clone.notificationStatuses"}})],1),_c('v-divider'),_c('v-btn',{staticClass:"mt-8",attrs:{"depressed":"","block":"","disabled":!valid,"color":"primary"},on:{"click":function($event){return _vm.savePreferences(clone, save)}}},[_vm._v("Save")])]}}],null,false,3731604919)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }