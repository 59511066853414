<template lang="pug">
div( class="w-full px-4 xl:px-0 xl:w-8/12 2xl:max-w-4xl mx-auto" )
  v-tabs.mb-8.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden(
    grow
    v-model="tab"
  )
    v-tab Account
    v-tab Notifications

    v-tabs-items( v-model="tab" )
      v-tab-item.pa-4.pa-md-8.border-t
        FormWrapper(
          v-if="!isUserLoading"
          :item="user"
        )
          template( v-slot="{ clone, valid, rules, save }" )
            h5 Identity
            v-divider.mb-8

            .d-flex.justify-content-between.gap-8.mb-8
              v-text-field(
                hide-details
                label="First Name"
                outlined
                dense
                v-model="clone.firstName"
                :rules="[rules.required]"
              )

              v-text-field(
                hide-details
                label="Last Name"
                outlined
                dense
                v-model="clone.lastName"
                :rules="[rules.required]"
              )

            v-text-field.mb-8(
              hide-details
              label="Email"
              outlined
              dense
              v-model="email"
              :rules="[rules.required]"
            )

            v-divider

            v-btn.mt-8(
              @click="saveUser(save)"
              depressed
              block
              :disabled="!valid"
              color="primary"
            ) Save

      v-tab-item.pa-4.pa-md-8.border-t
        FormWrapper(
          v-if="!isUserLoading"
          :item="userPreferences"
        )
          template( v-slot="{ clone, valid, save }" )
            .text-gray-600 Receive Emails
            .text-xs.text-gray-400.mb-2 Opt in to receive emails when selected statuses are assigned.

            .d-flex.mb-8( :class="'flex-column align-start align-md-center flex-md-row mt-6 w-1/2'" )
              v-select(
                :class="{ 'opacity-50': !clone.byEmail }"
                hide-details
                :items="statuses"
                dense
                v-model="clone.emailStatuses"
                multiple
                outlined
                label="Statuses"
              )

            .text-gray-600 Recieve Notifications
            .text-xs.text-gray-400.mb-2 Opt in to receive in-app notifications when selected statuses are assigned.

            .d-flex.mb-8( :class="'flex-column align-start align-md-center flex-md-row mt-6 w-1/2'" )
              v-select(
                :class="{ 'opacity-50': !clone.byNotification }"
                hide-details
                :items="statuses"
                dense
                v-model="clone.notificationStatuses"
                multiple
                outlined
                label="Statuses"
              )

            v-divider

            v-btn.mt-8(
              @click="savePreferences(clone, save)"
              depressed
              block
              :disabled="!valid"
              color="primary"
            ) Save
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mapActions } from 'vuex'
import useApiFind from '@/api/useApiFind'
import { useGet } from 'feathers-vuex'
import formValidations from '@/helpers/formValidations'
import FormWrapper from '../../components/FormWrapper.vue'

export default {
  name: "Account",

  components: { FormWrapper },

  setup(props, { root: { $store, $snackSuccess, $FeathersVuex, $router } }) {
    const {
      User,
      UserPreference,
      ExpenseStatuses
    } = $FeathersVuex.api
    const {
      id: userId,
      email,
      firstName,
      lastName,
      roleId
    } = $store.getters['auth/user'];

    const { valid, rules } = formValidations();
    const oldPassword = ref("");
    const newPassword = ref("");
    const confirmPassword = ref("");
    const tab = ref('account');
    const showUserSelect = ref(false);

    const { items: statuses, isPending: isStatusesPending } = useApiFind({
      model: ExpenseStatuses,
      params: {
        query: { $nin: ['incomplete'] }
      }
    })

    const { item: user, isPending: isUserLoading } = useGet({
      model: User,
      id: userId,
      params: {
        query: { $eager: '[userPreferences]' }
      }
    })

    const userPreferences = computed(() => user.value &&
      new UserPreference(user.value.userPreferences))

    const saveUser = async (save) => {
      try {
        await save()
        $snackSuccess('Account Saved');
        $router.go(0);
      } catch(e) {
        console.log(e);
      }
    }

    const savePreferences = async (clone, save) => {
      try {
        console.log(clone)
        await save()
        $snackSuccess('Preferences Saved');
      } catch(e) {
        console.log(e);
      }
    }

    return {
      valid,
      rules,
      email,
      firstName,
      lastName,
      tab,
      showUserSelect,
      isOrgAdmin: [3,5].includes(roleId),

      statuses,
      isStatusesPending,

      user,
      isUserLoading,
      userPreferences,

      oldPassword,
      newPassword,
      confirmPassword,

      savePreferences,
      saveUser
    };
  },
  methods: {
    ...mapActions("auth", ["authenticate"]),

    async passwordChange() {
      const { AuthManagement } = this.$FeathersVuex.api;
      const authManagement = new AuthManagement({
        action: "passwordChange",
        value: {
          user: { email: this.email },
          oldPassword: this.oldPassword,
          password: this.newPassword
        }
      });

      try {
        await authManagement.create();
        this.$snackSuccess("Password successfully updated");
        this.$refs.updatePasswordForm.reset();
      }
      catch ($e) {
        this.$snackError($e.message);
      }
    }
  },
}
</script>

<style>
</style>